<template>
    <div class="py-4 container-fluid">
        <div class=" row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <!-- <h2>{{ $route.params.id }}</h2> -->
                    </div>
                    <div class="card-body p-4">
                        <div class="row m-0 p-0">
                            <div class="col-6 d-flex">
                                <div class="avatar avatar-xl position-relative">
                                    <img :src="student.profile ? 'https://softworica.com/storage/' + student.profile : 'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                                        alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
                                </div>
                                <div class="mx-3">
                                    <div class="d-flex gap-2">
                                        <h4>{{ student.firstname }}</h4>
                                        <h4>{{ student.middlename }}</h4>
                                        <h4>{{ student.lastname }}</h4>
                                    </div>
                                    <small>{{ student.email }}</small>
                                </div>

                            </div>
                            <div class="col-6 d-flex gap-2 justify-end m-0 p-0">
                                <button @click="indicateDownloading" id="printButton"
                                    :data-name="this.student.firstname + '_' + this.student.lastname"
                                    onclick="downloadCertificate()" class="btn btn-danger btn-sm">
                                    {{ isDownloadinfStarted ? 'Downloading Started' : 'Download Certificate' }}</button>
                                <button class="btn btn-outline-secondary btn-sm">Edit</button>
                                <button class="btn btn-primary btn-sm" @click="downloadQr">Download QR</button>
                            </div>
                            <div class="col-12 row m-0 p-0 mt-3">
                                <div class="col-3 card p-3">
                                    <qrcode-vue id="qrCode"
                                        :value="'https://softworica.com/student/validate/' + student.qrtoken" size="600"
                                        style="width:100%; height: auto;" level="H"></qrcode-vue>
                                </div>
                                <div class="col-9">
                                    <div class="card row m-0 p-0 p-4">
                                        <div class="col-12 m-0 p-0">
                                            <h4 class="fw-bold fs-5 text-dark">Profile Checklist : </h4>

                                        </div>
                                        <div class="col-12" style="border-bottom: 3px solid rgb(0, 94, 255);"></div>
                                        <div class="col-12 row m-0 p-0">
                                            <div class="col-4 p-0 pt-3">
                                                <p class="m-0 fw-normal text-dark">Date of Birth</p>
                                                <p class="m-0 fw-normal text-dark">Date of Inrolled In</p>
                                                <p class="m-0 fw-normal text-dark">Date of Course Finished</p>
                                                <!-- <p class="m-0 fw-normal text-dark">Attached Image</p> -->
                                                <p class="m-0 fw-normal text-dark">Parent's Name</p>
                                                <p class="m-0 fw-normal text-dark">Contact Number</p>
                                                <p class="m-0 fw-normal text-dark">Permanent Address</p>
                                            </div>
                                            <div class="col-1 p-0 pt-3">
                                                <p class="m-0 fw-normal text-dark">:</p>
                                                <p class="m-0 fw-normal text-dark">:</p>
                                                <p class="m-0 fw-normal text-dark">:</p>
                                                <p class="m-0 fw-normal text-dark">:</p>
                                                <p class="m-0 fw-normal text-dark">:</p>
                                                <p class="m-0 fw-normal text-dark">:</p>

                                            </div>
                                            <div class="col-5 p-0 pt-3">
                                                <p class="m-0 fw-normal text-dark"> <span class="text-primary"
                                                        v-if="student.dob">AVAILABLE</span> <small class="text-danger"
                                                        v-else>NOT AVAILABLE</small> </p>
                                                <p class="m-0 fw-normal text-dark"> <span class="text-primary"
                                                        v-if="student.enrolled_in">AVAILABLE</span> <small
                                                        class="text-danger" v-else>NOT AVAILABLE</small> </p>
                                                <p class="m-0 fw-normal text-dark"> <span class="text-primary"
                                                        v-if="student.enrolled_out">AVAILABLE</span> <small
                                                        class="text-danger" v-else>NOT AVAILABLE</small> </p>
                                                <p class="m-0 fw-normal text-dark"> <span class="text-primary"
                                                        v-if="student.father_name || student.mother_name">AVAILABLE</span>
                                                    <small class="text-danger" v-else>NOT AVAILABLE</small>
                                                </p>
                                                <p class="m-0 fw-normal text-dark"> <span class="text-primary"
                                                        v-if="student.contact">AVAILABLE</span> <small class="text-danger"
                                                        v-else>NOT AVAILABLE</small> </p>
                                                <p class="m-0 fw-normal text-dark"> <span class="text-primary"
                                                        v-if="student.permanent_address">AVAILABLE</span> <small
                                                        class="text-danger" v-else>NOT AVAILABLE</small> </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <hr>
                        <form action="" @submit.prevent="updateUser()">
                            <div class="row mt-2">
                                <input id="file" type="file" @change="onFileChange">
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">First name</label>
                                    <input required placeholder="Firstname" class="form-control" type="text"
                                        v-model="student.firstname" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Middle name</label>
                                    <input class="form-control" placeholder="Middlename" type="text"
                                        v-model="student.middlename" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Last name</label>
                                    <input required class="form-control" placeholder="Lastname" type="text"
                                        v-model="student.lastname" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Username</label>
                                    <input class="form-control" placeholder="Username" type="text"
                                        v-model="student.username" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Email address</label>
                                    <input class="form-control" placeholder="Email" type="text" v-model="student.email" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Contact</label>
                                    <input required class="form-control" placeholder="Contact No" type="text"
                                        v-model="student.contact" />
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <p class="text-uppercase text-sm">Contact Information</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Address</label>
                                    <input required class="form-control" placeholder="Address" type="text"
                                        v-model="student.address" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Permanent Address</label>
                                    <input class="form-control" placeholder="Permanent Address" type="text"
                                        v-model="student.permanent_address" />
                                </div>
                                <div class="col-md-4 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Course</label>
                                    <select required v-model="student.course_id" class="form-control">
                                        <option value="0">Select Courses</option>
                                        <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name
                                        }}</option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Group</label>
                                    <select required v-model="student.group_id" class="form-control">
                                        <option value="0">Choose Group</option>
                                        <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Batch</label>
                                    <select required v-model="student.batch_id" class="form-control">
                                        <option value="null">Choose Batch</option>
                                        <option v-for="batch in batches" :key="batch.id" :value="batch.id" >{{ batch.name }} ({{ batch.status }})
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Date of Birth</label>
                                    <input class="form-control" placeholder="Date of Birth" type="date"
                                        v-model="student.dob" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Enrolled In</label>
                                    <input required class="form-control" placeholder="Date of class enrolled" type="date"
                                        v-model="student.enrolled_in" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Finished At</label>
                                    <input class="form-control" placeholder="Date of class finished" type="date"
                                        v-model="student.enrolled_out" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Father's Name</label>
                                    <input class="form-control" placeholder="Father's Name" type="text"
                                        v-model="student.father_name" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Mother's Name</label>
                                    <input class="form-control" placeholder="Father's Name" type="text"
                                        v-model="student.mother_name" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Gender</label>
                                    <select required v-model="student.gender" class="form-control">
                                        <option value="">Choose Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="example-text-input" class="form-control-label">Certificate Issued
                                        Date</label>
                                    <input class="form-control" placeholder="Date of Certificate Issue" type="date"
                                        v-model="student.certificate_issed_date" />
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <!-- <p class="text-uppercase text-sm mb-1">Info</p> -->
                            <div class="row m-0 p-0">
                                <div class="col-8 m-0 p-0">
                                    <label for="example-text-input" class="form-control-label">MORE INFO</label>
                                    <input v-model="student.info" class="form-control">
                                </div>
                                <div class="col-4">
                                    <label for="example-text-input" class="form-control-label">Profile Filled Status</label>
                                    <select required v-model="student.filled_status" class="form-control">
                                        <option value="incomplete">Incomplete</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                    <!-- <p>{{ student.filled_status }}</p> -->
                                </div>

                            </div>
                            <div class="row mt-2">

                                <div class="col-12">
                                    <button :class="iserror ? 'btn btn-warning' : 'btn btn-primary'">{{ issaving ? 'Saving please wait' : 'Save'}} {{ iserror ? ' - Error while saving' : '' }}</button>
                                </div>
                            </div>
                        </form>
                        <div class="col-12 card p-3 mb-2">
                            <div class="col-12 m-0 p-0">
                                <h5 class="fs-5 fw-bolder text-black">Assign Courses</h5>
                            </div>
                            <div class="col-12">
                                <!-- {{ selectedCourses }} -->
                                <table class="table">
                                    <thead>
                                        <tr class="fw-bold text-black">
                                            <td>SN</td>
                                            <td>Course Info</td>
                                            <td>Course Fee</td>
                                            <td>Assigned</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(course, index) in courses" :key="course.id" class="text-black" style="font-size: 14px !important;">
                                            <td>{{ index+1 }}</td>
                                            <td>{{course.name}}</td>
                                            <td>Rs 1200</td>
                                            <td><input v-model="selectedCourses" :value="course.id" type="checkbox"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 p-0" style="overflow-x: scroll;">
                            <div style="zoom: 0.9;" class="a4-card" id="pdfContent">
                                <!-- Content for A4-sized card -->
                                <certificate-content v-if="student.firstname != null"
                                    :student="student"></certificate-content>
                                <img v-if="student.course_id == 1"
                                    style="width: 100%; position: absolute; top:0px; left:0px; z-index:1;"
                                    src="../../assets/myimgs/basic_certificate_template.png" alt="" sizes="" srcset="">
                                <img v-if="student.course_id == 2"
                                    style="width: 100%; position: absolute; top:0px; left:0px; z-index:1;"
                                    src="../../assets/myimgs/diploma_certificate_template.jpg" alt="" sizes="" srcset="">
                                <img v-if="student.course_id == 3"
                                    style="width: 100%; position: absolute; top:0px; left:0px; z-index:1;"
                                    src="../../assets/myimgs/basic_certificate_template.png" alt="" sizes="" srcset="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- NEW ROW -->

        </div>
        <div class="mt-4 row">
            <div class="col-12">

            </div>
        </div>
    </div>
</template>
  
<script>
import axiosConfig from "../../baseaxios.js";
import CertificateContent from '../components/students/certificate-content.vue';
import QrcodeVue from 'qrcode.vue';

export default {
    components: {
        QrcodeVue,
        CertificateContent
    },
    data() {
        return {
            student: {},
            issaving : false,
            iserror : false,
            courses: [],
            groups: [],
            isDownloadinfStarted: false,
            selectedCourses : [],
            batches : [],
        }
    },
    mounted() {
        axiosConfig.get(`basic/view-student/${this.$route.params.id}`).then(resp => {
            this.student = resp.data;
            for (let i = 0; i < resp.data.courses.length; i++) {
                const selectedCourse = resp.data.courses[i];
                console.log(selectedCourse);
                this.selectedCourses.push(selectedCourse.id);
                
            }
            // console.log(resp.data)
        })
        axiosConfig.get("basic/get-courses").then(resp => {
            // console.log(resp.data);
            this.courses = resp.data;
        })
        axiosConfig.get("basic/get-groups").then(resp => {
            // console.log(resp.data);
            this.groups = resp.data;
        }),
        axiosConfig.get('/back/batches/list').then(resp => {
            this.batches = resp.data;
            // console.log(resp);
        })
    },
    computed: {
        getCertificateTemplate() {
            if (this.student.course_id == 1) {
                return "../../assets/myimgs/basic_certificate_template.png";
            }

            if (this.student.course_id == 1) {
                return "../../assets/myimgs/diploma_certificate_template.jpg";
            }

            return "NO";
        }
    },
    methods: {
        onFileChange(event) {
            // Get the selected file from the input
            const file = event.target.files[0];
            // Assign the selected file to the user's image field
            this.student.profile = file;
        },
        updateUser() {
            this.issaving = true;
            this.iserror = false;
            const formData = new FormData();

            // Append user data fields to the FormData object
            Object.keys(this.student).forEach(key => {
                console.log(this.student[key]);
                formData.append(key, String(this.student[key]) == 'null' ? "" : this.student[key]);
            });
            formData.append("selectedCourses", String(this.selectedCourses));

            console.log(formData);

            // Append the image file to the FormData object
            formData.append('image', this.student.profile);
            axiosConfig.post(`back/students/update/${this.$route.params.id}`, formData).then(resp => {
                console.log(resp.data);
                this.issaving = false;
                this.iserror = false;
                // this.groups = resp.data;
            }).catch(ex => {
                this.issaving = false;
                this.iserror = true;
                console.error(ex);
            })
            // console.log(this.user);
        },

        downloadQr() {
            var link = document.createElement('a');
            link.download = `QR_${this.student.firstname}_${this.student.lastname}_SOFTWORICA.png`;
            link.href = document.getElementById('qrCode').toDataURL()
            link.click();
        },

        indicateDownloading() {
            this.isDownloadinfStarted = true;
            console.log("Downloading");
        }



    }
};
</script>
  
<style>
.form-control {
    border-radius: 0px !important;
}
.a4-card {
    width: 297mm;
    /* Width of A4 in landscape mode */
    height: 210mm;
    /* Height of A4 in landscape mode */
    border: 2px solid #ccc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}</style>