<template>
    <div class="certificate-content">
        <div id="studentRegistrationInfo">STUDENT SERIAL NO: {{ student.qrtoken }}</div>
        <div id="mainUserPhotograph">
            <img :src="studentImage" alt="">
        </div>



        <div id="mainUserQR">
            <qrcode-vue id="qrCode" :value="'https://softworica.com/student/validate/' + student.qrtoken" size="600"
                style="width:100%; height: auto;" level="H"></qrcode-vue>
        </div>
        <!-- <div id="mainUserPhotograph"></div> -->
        <div style="padding: 0px 95px; margin-bottom: 60px; text-align: justify;">
            <p style="color: rgb(0, 0, 0); font-weight: 600; font-size: 15px; text-transform: uppercase;">This is to certify
                that <strong>{{ student.gender == 'male' ? 'Mr.' : 'Mrs.' }}</strong> <strong>{{ student.firstname }} <span
                        v-if="student.middlename">{{ student.middlename
                        }}</span> {{ student.lastname }}</strong>, born on <strong>{{ student.dob }} AD</strong>, {{ student.gender == 'male' ? 'son' : 'daughter' }} of
                <strong>Mr. {{ student.father_name }} & Mrs. {{ student.mother_name }} </strong>,
                permanent resident of <strong>{{ student.permanent_address }}</strong> district has successfully completed
                the following course
                conducted
                by this organization from <strong>{{ formattedEntolledDate }}</strong> to <strong>{{ formattedFinishedDate
                }}</strong>.
            </p>
        </div>
        <div id="certificateIssueDate"> {{ certificateIssuedDate }}</div>
        <div id="qrcode_note"> <small>SCAN QR ABOVE TO VALIDATE DOCUMENT</small> </div>

    </div>
</template>
<script>
import axiosConfig from "../../../baseaxios";

import QrcodeVue from 'qrcode.vue';
export default {
    name: "certificate-component",
    props: [
        'student'
    ],
    data() {
        return {
            studentImage: ""
        }
    },
    components: { QrcodeVue },
    mounted() {
        this.getSrcFromImg();
        console.log(this.student.profile)
        // axios.get('http://localhost:8000/storage/' + this.student.profile).then(resp => {
        //     console.log(resp)
        // })
        // Import Axios if you're working in a Node.js environment
        // const axios = require('axios');

        // URL of the image you want to fetch
       



    },
    computed: {
        formattedEntolledDate() {
            return this.getFormattedDate(this.student.enrolled_in);
        },
        formattedFinishedDate() {
            return this.getFormattedDate(this.student.enrolled_out);
        },
        myimageUrl() {
            return this.student.profile ? 'http://localhost:8000/storage/' + this.student.profile : 'https://ui-avatars.com/api/?name=' + this.student.firstname + ' ' + this.student.lastname;
        },
        certificateIssuedDate() {
            return this.getFormattedDate(this.student.certificate_issed_date);
        }
    },
    methods: {
        getFormattedDate(dateString) {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            const suffix = this.getSuffix(day);
            return `${day}${suffix} ${month} ${year}`;
        },
        getSuffix(day) {
            if (day >= 11 && day <= 13) {
                return 'th';
            }
            switch (day % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        },
        getSrcFromImg() {
            axiosConfig.get('/img/' + this.student.profile).then(resp => {
                this.studentImage = resp.data.image;
                // console.log(resp.data.image);
            })

        }
    },
};
</script>
<style>
p {
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
}

.certificate-content {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 2;
    display: flex;
    /* position: relative; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#mainUserPhotograph {
    position: absolute;
    /* background : url; */
    background-size: cover;
    background-repeat: no-repeat;
    border: 6px solid black;
    width: 14%;
    /* height: 23%; */
    top: 12%;
    border-radius: 10px;
    right: 5%;
}

#mainUserPhotograph img {
    width: 100%;
    border-radius: 5px;
}

#mainUserQR {
    position: absolute;
    /* background : url; */
    background-size: cover;
    background-repeat: no-repeat;
    width: 17%;
    /* height: 23%; */
    top: 57%;
    border-radius: 10px;
    left: 10.4%;
}

strong {
    font-weight: 900 !important;
}

#studentRegistrationInfo {
    position: absolute;
    top: 2%;
    font-weight: 600;
    color: black;
    font-size: 14px;
    right: 7%;
}

#certificateIssueDate {
    position: absolute;
    bottom: 8.7%;
    color: black;
    font-weight: 600;
    left: 9.3%;
    text-align: center;

}

#qrcode_note {
    position: absolute;
    bottom: 13.7%;
    color: black;
    font-weight: 600;
    left: 9.8%;
    /* width: 100%; */
    font-size: 11px;
    /* font-style: italic; */
    text-align: center;
}
</style>